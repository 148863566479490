import * as Sentry from '@sentry/react';
import {
  InputField,
  SurveyMCInputField,
  SurveySelectField,
} from 'app/components';
import { ButtonCore, ImageSwiper, PageLoading } from 'app/components/Common';
import { CustomBreadcrumbs } from 'app/components/Common/CustomBreadcrumbs';
import { EmptyResultPage } from 'app/components/Common/EmptyResultPage';
import { MobileBottomButton } from 'app/components/Common/MobileBottomButton';
import { MobileHeader } from 'app/components/Common/MobileHeader';
// import { PleaseLoginFirst } from 'app/components/Common/PleaseLoginFirst';
import { BottomHeader } from 'app/components/Layout/BottomHeader';
import SuccessIcon from 'assets/icons/success.svg';
import 'assets/scss/Pages/SurveyPage/SurveyPage.scss';
import { Form, Formik } from 'formik';
import { FontHelper, toastError } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import {
  CourseApplication,
  CourseSchedules,
  EventApplication,
  Survey,
} from 'models';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getOneCourseApplication,
  getOneCourseApplicationSchedules,
} from 'services/CourseService';
import { getOneEventApplication } from 'services/EventServices';
import {
  getOneSurvey,
  getSurveyResponses,
  submitOneSurveyResponse,
} from 'services/SurveyService';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import * as Yup from 'yup';

type SurveyPageParams = {
  surveyId: string;
  eventApplicationId?: string;
  courseApplicationId?: string;
  courseScheduleId?: string;
};

interface FormItem {
  answer: string[];
  courseId?: number | null;
  eventId?: number | null;
}

const SCHEMA = Yup.object({
  answer: Yup.array().of(Yup.string().min(1, '必填項目').required('必填項目')),
});

export const SurveyPage = () => {
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const {
    surveyId,
    eventApplicationId,
    courseApplicationId,
    courseScheduleId,
  } = useParams<SurveyPageParams>();
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSecondStep, setLoadingSecondStep] = useState<boolean>(true);
  const [loadingThirdStep, setLoadingThirdStep] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [enableSubmit, setEnableSubmit] = useState<boolean>(false);
  const [CourseApplication, setCourseApplication] =
    useState<CourseApplication>();
  const [HasValidCourseApplication, setHasValidCourseApplication] =
    useState<boolean>(false);
  const [EventApplication, setEventApplication] = useState<EventApplication>();
  const [courseSchedulesByApplicationId, setcourseSchedulesByApplicationId] =
    useState<CourseSchedules[]>([]);
  const [form, setForm] = useState<FormItem>({
    answer: [],
  });
  const { size, isMobile } = useBreakpoint();
  const navigate = useNavigate();

  const getScheduleData = useCallback(async courseApplicationId => {
    setLoadingThirdStep(true);
    try {
      const CourseScheduleRes = await getOneCourseApplicationSchedules({
        limit: 1000,
        offset: 0,
        courseApplicationId: Number(courseApplicationId),
        desc: 'courseScheduleId',
      });
      setcourseSchedulesByApplicationId(CourseScheduleRes.rows.map(row => row));
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('獲取課室資料失敗');
    } finally {
      setLoadingThirdStep(false);
    }
  }, []);

  const getCourseApplicationData = useCallback(async courseApplicationId => {
    setLoadingSecondStep(true);
    try {
      const CourseApplicationRes = await getOneCourseApplication(
        courseApplicationId,
      );
      setCourseApplication(CourseApplicationRes);
      setHasValidCourseApplication(true);
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('獲取已報名課程資料失敗');
    } finally {
      setLoadingSecondStep(false);
    }
  }, []);

  const getEventApplicationData = useCallback(async eventApplicationId => {
    setLoadingSecondStep(true);
    try {
      const EventApplicationRes = await getOneEventApplication(
        eventApplicationId,
      );
      setEventApplication(EventApplicationRes);
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('獲取已報名活動資料失敗');
    } finally {
      setLoadingSecondStep(false);
      setLoadingThirdStep(false);
    }
  }, []);

  const getSurvey = useCallback(async () => {
    if (surveyId) {
      try {
        setLoading(true);

        const surveyRes = await getOneSurvey(surveyId);
        setSurvey(surveyRes);
        if (Auth) {
          const listResponse = await getSurveyResponses({
            surveyId: surveyId,
            limit: 5,
            offset: 0,
          });
          if (
            (surveyRes?.maxSubmissionPerPerson &&
              listResponse.count < surveyRes.maxSubmissionPerPerson) ||
            listResponse.count === 0
          ) {
            setEnableSubmit(true);
          }
        }

        const answers: string[] = [];
        surveyRes.questions.forEach(() => {
          answers.push('');
        });
        setForm({ answer: answers });
      } catch (err) {
        // console.log(err);
        Sentry.captureException(err);
        setSurvey(null);
        toastError('獲取評價資料失敗');
      } finally {
        setLoading(false);
      }
    }
  }, [surveyId, Auth]);

  const checkScheduleIdValid = () => {
    if (courseSchedulesByApplicationId.length > 0 && courseScheduleId) {
      return (
        courseSchedulesByApplicationId.some(
          schedule => schedule.courseScheduleId === Number(courseScheduleId),
        ) &&
        courseSchedulesByApplicationId.find(
          schedule => schedule.courseScheduleId === Number(courseScheduleId),
        )?.surveyId === Number(surveyId)
      );
    }
    return false;
  };

  useEffect(() => {
    getSurvey();
    if (courseApplicationId) {
      getCourseApplicationData(courseApplicationId);
    } else if (eventApplicationId) {
      getEventApplicationData(eventApplicationId);
    } else {
      setLoadingSecondStep(false);
    }

    if (courseApplicationId && HasValidCourseApplication) {
      getScheduleData(courseApplicationId);
    } else {
      setLoadingThirdStep(false);
    }
    // if (Auth) {
    //   getSurvey();
    //   if (courseApplicationId) {
    //     getCourseApplicationData(courseApplicationId);
    //   } else if (eventApplicationId) {
    //     getEventApplicationData(eventApplicationId);
    //   } else {
    //     setLoadingSecondStep(false);
    //   }

    //   if (courseApplicationId && HasValidCourseApplication) {
    //     getScheduleData(courseApplicationId);
    //   } else {
    //     setLoadingThirdStep(false);
    //   }
    // } else {
    //   setLoading(false);
    //   setLoadingSecondStep(false);
    //   setLoadingThirdStep(false);
    // }
  }, [
    getSurvey,
    getCourseApplicationData,
    getEventApplicationData,
    getScheduleData,
    courseApplicationId,
    eventApplicationId,
    HasValidCourseApplication,
    Auth,
  ]);

  const onSubmit = async (values: FormItem) => {
    try {
      setSubmitLoading(true);
      await submitOneSurveyResponse(surveyId!, {
        date: moment().locale('en').format('YYYY-MM-DD'),
        responses: [...values.answer],
        eventApplicationId: eventApplicationId
          ? Number(eventApplicationId)
          : undefined,
        courseApplicationId: courseApplicationId
          ? Number(courseApplicationId)
          : undefined,
        courseScheduleId: courseScheduleId
          ? Number(courseScheduleId)
          : undefined,
        courseId: CourseApplication?.courseId
          ? CourseApplication.courseId
          : undefined,
        eventId: EventApplication?.eventId
          ? EventApplication.eventId
          : undefined,
      });
      setSuccess(true);
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('提交失敗，請重試。');
    } finally {
      setSubmitLoading(false);
    }
  };

  const renderText = () => {
    if (!Auth) {
      return '請先登入';
    }
    if (survey?.isMemberOnly && Auth.user?.userType !== '會員') {
      return '只限會員提交';
    }
    if (!enableSubmit) {
      return '你已提交此表格';
    }
    return '提交';
  };

  let Breadcrumb: any = [];

  if (eventApplicationId) {
    Breadcrumb = [
      {
        title: '活動',
        active: true,
      },
      {
        title: '活動評價',
        active: true,
      },
    ];
  } else if (courseApplicationId) {
    Breadcrumb = [
      {
        title: '課程',
        active: true,
      },
      {
        title: '課程評價',
        active: true,
      },
    ];
  } else {
    Breadcrumb = [
      {
        title: '評價',
        active: true,
      },
      {
        title: `${survey?.title}`,
        active: true,
      },
    ];
  }

  // if (!Auth) {
  //   return (
  //     <>
  //       {isMobile ? (
  //         <>
  //           <MobileHeader
  //             title={`${survey && survey.title ? survey.title : '評價'}`}
  //           />

  //           <BottomHeader />
  //         </>
  //       ) : null}
  //       <PleaseLoginFirst />
  //     </>
  //   );
  // }

  if (loading || loadingSecondStep || loadingThirdStep) {
    return (
      <div className="survey-page page-container-with-header">
        {isMobile ? (
          <>
            <MobileHeader
              title={`${survey && survey.title ? survey.title : '評價'}`}
            />

            <BottomHeader />
          </>
        ) : null}
        <PageLoading />
      </div>
    );
  }

  if (!survey || (courseScheduleId && !checkScheduleIdValid())) {
    return (
      <>
        {isMobile ? (
          <>
            <MobileHeader
              title={`${survey && survey.title ? survey.title : '評價'}`}
            />

            <BottomHeader />
          </>
        ) : null}
        <EmptyResultPage />
      </>
    );
  }

  if (success) {
    return (
      <div className="survey-page page-container-with-header">
        <div className="survey-card">
          <div className="survey-success-page">
            <img src={SuccessIcon} alt="survey success" />
            <div className="font-18px-bold text-color-purple mt-4">
              {survey?.successTitle}
            </div>
            <div className="font-16px-bold text-color-primary">
              {survey?.successSubtitle}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="survey-page page-container-with-header">
      {isMobile ? (
        <MobileHeader
          title={`${survey && survey.title ? survey.title : '評價'}`}
        />
      ) : (
        <div className="survey-page-breadcrumb">
          <CustomBreadcrumbs items={Breadcrumb} />
        </div>
      )}

      <div className="survey-card">
        <div
          className="survey-image-container"
          style={{
            marginBottom:
              survey?.images && survey.images.length > 1 ? '30px' : '20px',
          }}
        >
          <ImageSwiper images={survey?.images || []} />
          <div className="swiper-pagination"></div>
        </div>

        <div className="survey-description-container">
          <div
            className={`${FontHelper(
              size,
              16,
              20,
              20,
              'bold',
            )} text-color-orange ${isMobile ? 'mb-3' : 'mb-4'}`}
          >
            {survey?.title || null}
          </div>
          <ReactQuill
            theme="snow"
            value={survey?.description}
            readOnly
            modules={{
              toolbar: false,
            }}
            className="survey-rice-text"
          />
        </div>
      </div>
      <Formik
        initialValues={form}
        validationSchema={SCHEMA}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            {survey?.questions.map((question, index) => (
              <div className="survey-card" key={index}>
                <div className="card-wrapper">
                  {question.type === 'input' ? (
                    <InputField
                      name={`answer[${index}]`}
                      label={question.title}
                      type="textarea"
                      requiredSymbol
                    />
                  ) : null}
                  {question.type === 'select' ? (
                    <SurveySelectField
                      name={`answer[${index}]`}
                      label={question.title}
                      requiredSymbol
                      options={question.options}
                      firstOptionRemarks={question.firstOptionRemarks}
                      lastOptionRemarks={question.lastOptionRemarks}
                    />
                  ) : null}
                  {question.type === 'mc' ? (
                    <SurveyMCInputField
                      name={`answer[${index}]`}
                      label={question.title}
                      options={question.options}
                      requiredSymbol
                    />
                  ) : null}
                </div>
              </div>
            ))}
            {isMobile ? (
              <MobileBottomButton
                children={
                  <div className="button-container-row">
                    <ButtonCore
                      containerStyle={{ width: '400px' }}
                      customClass={`font-18px-semibold ${
                        enableSubmit ? 'text-color-purple' : 'disabled-button'
                      }`}
                      type="submit"
                      loading={submitLoading}
                      disabled={
                        !enableSubmit ||
                        submitLoading ||
                        !Auth ||
                        (survey?.isMemberOnly && Auth.user?.userType !== '會員')
                      }
                    >
                      {renderText()}
                    </ButtonCore>
                  </div>
                }
              />
            ) : (
              <div className="button-container-row">
                <ButtonCore
                  containerStyle={{ width: '400px' }}
                  customClass={`font-18px-semibold ${
                    !(!enableSubmit && Auth
                      ? true
                      : false ||
                        submitLoading ||
                        // !Auth ||
                        (Auth &&
                        survey?.isMemberOnly &&
                        Auth.user?.userType !== '會員'
                          ? true
                          : false))
                      ? 'text-color-purple'
                      : 'disabled-button'
                  }`}
                  onClick={() => {
                    if (!Auth) {
                      navigate('/auth');
                      return;
                    }
                  }}
                  type="submit"
                  loading={submitLoading}
                  disabled={
                    !enableSubmit && Auth
                      ? true
                      : false ||
                        submitLoading ||
                        // !Auth ||
                        (Auth &&
                        survey?.isMemberOnly &&
                        Auth.user?.userType !== '會員'
                          ? true
                          : false)
                  }
                >
                  {renderText()}
                </ButtonCore>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
